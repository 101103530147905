import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthenticationService} from 'app/server/services';
import {ErrorService} from "../services/error.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   */
  constructor(private _router: Router, private _errorService: ErrorService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if ([401, 403].indexOf(err.status) !== -1 && err.url !== 'https://saz.previdir.it/api/user/getprofile' && err.url !== 'https://saz.previdir.it/api/auth/login' ) {
          // console.log(err);
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          this._router.navigate(['/miscellaneous/not-authorized']);

          // ? Can also logout and reload if needed
          // this._authenticationService.logout();
          // location.reload(true);
        }

          this._errorService._httpLoading.next(false);

        // throwError
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
